import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function Skcript() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c7_c7502ab8ac.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                Skcript
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                Skcript is not only a UI UX design agency but also a digital
                consulting firm that was founded in the year 2013.
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $50-$99 per hour
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 10-49
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="https://www.skcript.com/" target="_blank">
                  https://www.skcript.com/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | 3rd Floor, No 5, 1st Cross Rd, K.R.Colony, Krishna
                  Reddy Colony, Domlur, Bengaluru, Karnataka 560071
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                Skcript is not only a UI UX design agency but also a digital
                consulting firm that was founded in the year 2013. The company
                developed it's very own Product Discovery Workshop which helps
                companies kickstart their digital transformation and build MVPs.
                For startup companies, the Product Discovery Workshop helps to
                build MVPs within just a span of a few weeks. One of the team's
                focus is to improve business efficiency. Skcript can help
                established companies automate the process of their business
                that can improve the company's overall performance through
                Robotic Process Automa. The team at Skcript is proficient in
                advanced coding languages like Ruby on Rails and has experience
                working with Artificial Intelligence. The company also offers a
                14 day trial period offer to new customers. The team has
                collaborated with Intel, Bosch, Gerab, Prudence Software,
                Microsoft, and Google, to name a few in the past.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
